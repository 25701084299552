<template>
  <static-fullscreen-card>
    <template v-slot:title>({{ data.id }}) {{ data.name }}</template>
    <template v-slot:actions>
      <v-btn color="primary" @click="showEditDialog = true" title="Редактировать элемент">
        <btn-title icon="far fa-edit">Редактировать</btn-title>
      </v-btn>
    </template>
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="id"></edit-dialog>
      <s-quick-addDocument v-if="showAddDocDialog" v-model="showAddDocDialog" :id="id" :api="url" :modelDoc="addDocModel" />
    </portal>
    <v-row>
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading>
            <div class="px-3 py-1">информация</div>
          </template>
          <a-form-view v-model="data" :model="[model[1], model[6], model[7]]" :config="{ dense: true }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading>
            <div class="px-3 py-1">контакты</div>
          </template>

          <a-form-view :value="data" :model="[model[2], model[3], model[4], model[5]]" :config="{ dense: true }" class="block_info" />
        </material-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <material-card color="third">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(tab, i) in tabs" :key="i" class="mr-3">{{ tab.title }}</v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item
              ><v-card-text style="height: 300px" class="pa-0 pt-0">
                <a-table-f-api
                  ref="table1"
                  api="/mechti/objects"
                  :model="$store.getters['config/get'].models.objects.list"
                  :searchable="false"
                  :defaults="{ filters: { client_id: id }, sort: { key: 'id', order: 'DESC' } }"
                  @click="$router.push({ name: 'objects_view', params: { id: $event.row.id } })"
                />
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text style="height: 300px; overflow: auto">
                <v-fab-transition>
                  <v-btn fab dark color="green" absolute bottom small style="bottom: 25px" left class="v-btn--example" @click="addNewDocs('docs')">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-fab-transition>
                <a-view-filesdata :value="data.filesData" :category="1" />
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text style="height: 300px">
                <a-table-f-api
                  ref="table3"
                  api="/caller/calls"
                  :model="callModel"
                  :searchable="false"
                  :defaults="{ filters: { phone: [data.phone1, data.phone2] }, sort: { key: 'id', order: 'DESC' } }"
                />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
  </static-fullscreen-card>
</template>

<script>
import { autoHeightBlock } from "../../components/mixings";
export default {
  // mixins: [autoHeightBlock],

  components: {
    editDialog: () => import("./../dialogs/clientDialog"),
  },
  data() {
    return {
      id: 0,
      showEditDialog: false,
      showAddDocDialog: false,
      addDocModel: null,
      title: "",
      data: {},
      model: this.$store.getters["config/get"].models.clients.form,
      url: "/mechti/clients",
      tab: 0,
      tabs: [{ title: "Объекты" }, { title: "Документы" }, { title: "Разговоры" }],
      callModel: [
        {
          name: "calldate",
          type: "datetime",
          title: "Время",
          width: 130,
        },
        {
          name: "status",
          type: "select",
          options: this.$store.getters["config/get"].enums.callStatuses,
          title: "Статус",
          sortable: true,
          width: 100,
        },
        {
          name: "destination",
          type: "select",
          options: this.$store.getters["config/get"].enums.destinations,
          title: "Направление",
          sortable: true,
          width: 100,
        },
        {
          name: "manager_name",
          type: "string",
          filter: {
            type: "selectapi",
            name: "users.id",
            api: "/manager/users/select",
          },
          title: "Менеджер",
          sortable: true,
          width: 150,
        },
        {
          name: "billsec",
          type: "string",
          title: "Время разговора",
          sortable: true,
          width: 120,
        },
        {
          name: "recordingfile",
          type: "record",
          title: "Запись разговора",
          sortable: false,
          width: 350,
        },
      ],
    };
  },
  created() {
    this.$root.title = "Клиенты";
    this.id = Number(this.$route.params.id);
    this.fitchData();
  },

  watch: {
    showAddDocDialog(v) {
      if (!v) {
        this.fitchData();
      }
    },
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
  },
  methods: {
    addNewDocs(field) {
      this.addDocModel = this.model.find((el) => el.name == field) || null;
      if (!this.addDocModel) return;
      this.showAddDocDialog = true;
    },
    async fitchData() {
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
    },
  },
};
</script>
